import React, { useCallback, useEffect, useState } from 'react';
import FadeIn from 'react-fade-in';
import { useParams } from 'react-router-dom';
import './App.scss';

import logo from './logo.png'

interface Church {
  name: string;
  pictureUrl: string;
}

function App() {
  const params = useParams();
  const [church, setChurch] = useState<Church | undefined | null>(undefined);

  const fetchChurch = useCallback(async (churchId: string): Promise<Church> => {
    const response = await fetch(`https://api.saltplatform.com/church/${churchId}`);
    if (response.status !== 200) throw new Error("Failed to find church.");

    const body = await response.json();
    return body.church;
  }, []);

  useEffect(() => {
    const churchId = params.churchId;
    if (churchId === undefined) return;

    fetchChurch(churchId)
      .then((loadedChurch) => {
        setChurch(loadedChurch);
      })
      .catch(() => {
        setChurch(null); // This will trigger error message
      })
  }, [params.churchId, fetchChurch])

  if (church === undefined) {
    return <div className='loading'>
      <div className='loading-spinner' />
    </div>;
  }

  return (
    <FadeIn>
      <div className="App">
        <div className='launchpad-page'>
          <div className="logo-section">
            <img id="salt" src={logo} alt="Salt logo" />

            {church !== null ?
              <>
                <p>+</p>

                <img id="church" src={church.pictureUrl} alt={`${church.name}'s logo`} />
              </> :
              null
            }
          </div>

          <InstructionsView churchName={church !== null ? church.name : "your church"} />
        </div>
      </div>
    </FadeIn>
  );
}

function InstructionsView(props: {
  churchName: string
}) {
  return <div className='content'>
    <h2>
      👋 Welcome to Salt!
    </h2>

    <p>
      To get started with {props.churchName},
      follow these two easy steps:
    </p>

    <p>
      <strong>1.</strong> Download the app for <a href="https://apps.apple.com/us/app/salt-church-companion/id1614084359">Apple</a> or <a href="https://play.google.com/store/apps/details?id=com.saltplatform.salt">Android</a>
    </p>

    <p>
      <strong>2.</strong> Inside the app, tap “I have a QR code”,
      and scan the QR code one more time.
    </p>
  </div>
}

export default App;
